@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rotateCenter {
	-webkit-animation: rotateCenter 10s ease-in-out infinite normal both;
	        animation: rotateCenter 10s ease-in-out infinite normal both;
}
 @-webkit-keyframes rotateCenter {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotateCenter {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.cardBoxShadow {
  box-shadow: rgba(13, 38, 76, 0.19) 0px 9px 20px;
}

.slideRight {
	-webkit-animation: slideRight 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
	        animation: slideRight 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
}
 @-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}
@keyframes slideRight {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
}

.textShadowDropCenter {
	-webkit-animation: textShadowDropCenter 0.6s alternate both;
	        animation: textShadowDropCenter 0.6s alternate both;
}

@-webkit-keyframes textShadowDropCenter {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.9);
  }
}
@keyframes textShadowDropCenter {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.9);
  }
}

