@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Act as a border */
  .card-wrapper {
    @apply relative flex justify-center items-center bg-slate-400/50;
  }

  /* Gradient */
  .card-wrapper::before {
   
    /* background-color: red; */
    background: linear-gradient(
        90deg,
        /* rgb(171, 0, 250), */
        #7453E0,
       transparent,
       transparent
      );
      
    @apply absolute  h-[20%]  w-[100%] animate-border-spin content-[''];
    /* @apply absolute left-[-25%] top-[-25%] h-[150%] w-[150%] animate-border-spin content-['']; */
  }

  /* Body */
  .card-content {
    @apply absolute left-[4px] top-[4px] h-[calc(100%-8px)] w-[calc(100%-8px)]  bg-[#f9edd7];
  }
}
